.App {
  
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


#filter {
  height: 50px;
  width: 100%;
}

#filter > * {
  width: 18%;
}

#filterDate.top i::before{
  transform:rotate(180deg);
}
#filterDate.bottom i::before{
  transform:rotate(0deg);
}

tr {
  cursor: pointer;
}

tr:hover {
  opacity: .7;
}

.table-striped tr td:nth-child(1n) {
  font-weight: 700;
}
.table-striped tr td:nth-child(2n) {
  font-weight: 400;
}
#progress{
  background-color: #fff;
  padding: 10px;
  
}

a, a:link {
  color: #fff;
  text-decoration: none;
  font-weight: 600;
  font-size: 18px;
}

.card{
  max-width: 18rem;
}